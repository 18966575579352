import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Moment from 'react-moment';
import Currency from 'react-currency-formatter';
import Dropzone from 'react-dropzone';
import {connect, useSelector} from 'react-redux';

import {formatSize} from '../../components/common/formatter/FileSize';

import PaymentMethod from '../../components/invoice/paymentMethod';
import {addInvoiceDocument} from '../../actions/invoice.action';

const InvoiceModal = ({invoice, isOpen, toggle, isIncome, addInvoiceDocument}) => {
	const accessToken = useSelector(content => content.user.accessToken);

	const [uploadedDocuments, setUploadedDocuments] = useState([]);

	return (
		<Modal isOpen={isOpen} toggle={toggle} centered={true} size="full">
			<ModalHeader toggle={toggle}>
				{invoice.invoice_number}
			</ModalHeader>
			<ModalBody>
				<div className="card card-absolute border">
					<div className="card-header bg-secondary">
						<h5>Számla adatai</h5>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-lg-6">
								<h6>Eladó</h6>
								<div className="row">
									<div className="col-12">
										<h4>{invoice.seller_name}</h4>
									</div>
									<div className="col-12">{invoice.seller_address_city}</div>
									<div className="col-12">{invoice.seller_address_address_1}</div>
									<div className="col-12">{invoice.seller_address_postal_code}</div>
									<div className="col-12">{invoice.seller_address_country}</div>
								</div>
							</div>
							<div className="col-lg-6">
								<h6>Vevő</h6>
								<div className="row">
									<div className="col-12">
										<h4>{invoice.client_name}</h4>
									</div>
									<div className="col-12">{invoice.client_address_city}</div>
									<div className="col-12">{invoice.client_address_address_1}</div>
									<div className="col-12">{invoice.client_address_postal_code}</div>
									<div className="col-12">{invoice.client_address_country}</div>
								</div>
							</div>
						</div>
						<div className="row mt-3 pt-3 border-top">
							<div className="col-6 col-md-3">
								<h5>Számla kelte</h5>
								<Moment format='YYYY.MM.DD.'>{invoice.date}</Moment>
							</div>
							<div className="col-6 col-md-3">
								<h5>Teljesítés kelte</h5>
								<Moment format='YYYY.MM.DD.'>{invoice.fulfillment_date}</Moment>
							</div>
							<div className="col-6 col-md-3">
								<h5>Fizetési határidő</h5>
								<Moment format='YYYY.MM.DD.'>{invoice.due_date}</Moment>
							</div>
							<div className="col-6 col-md-3">
								<h5>Fizetési mód</h5>
								<PaymentMethod method={invoice.payment_method}/>
							</div>
						</div>
						<div className="row mt-3 pt-3 border-top">
							<div className="col-12 col-md-6">
								<h5>
									Nettó összeg:&nbsp;<Currency
									quantity={invoice.sub_total}
									currency={invoice.currency}
								/>
								</h5>
							</div>
							<div className="col-12 col-md-6">
								<h5>
									Bruttó végösszeg:&nbsp;<strong>
									<Currency
										quantity={invoice.total}
										currency={invoice.currency}
									/>
								</strong>
								</h5>
							</div>
						</div>
					</div>
				</div>
				<div className="card card-absolute border">
					<div className="card-header bg-secondary">
						<h5>Tételek</h5>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-12">
								<table className="table table-hover">
									<thead>
										<tr>
											<th>Megnevezés</th>
											<th>Mennyiség</th>
											<th>Nettó egységár</th>
											<th>Nettó ár</th>
											<th>ÁFA</th>
											<th>Bruttó ár</th>
										</tr>
									</thead>
									<tbody>
										{invoice.invoice_items.map((item, idx) => <tr key={idx}>
											<td>
												{item.name}
												{item.description.length > 0 ? <><br/><small>{item.description}</small></> : ''}
											</td>
											<td>{item.quantity} {item.unit}</td>
											<td>
												<Currency
													quantity={item.unit_price}
													currency={invoice.currency}
												/>
											</td>
											<td>
												<Currency
													quantity={item.sub_total}
													currency={invoice.currency}
												/>
											</td>
											<td>{item.vat.description}</td>
											<td>
												<Currency
													quantity={item.total}
													currency={invoice.currency}
												/>
											</td>
										</tr>)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="card card-absolute border">
					<div className="card-header bg-secondary">
						<h5>Dokumentumok</h5>
					</div>
					<div className="card-body">
						<ul className="list-group">
							{invoice.invoice_documents.map((document, idx) => <li className="list-group-item" key={idx}>
								<a
									href={`${process.env.PUBLIC_URL}/#`}
									onClick={(e) => {
										e.preventDefault();
										// TODO API file download
									}}
								>
									{document.original_filename}
								</a>
							</li>)}
							{uploadedDocuments.map((file, idx) => {
								return (
									<li key={`uploadedFile-${idx}`}>{file.name} ({file.size})</li>
								);
							})}
						</ul>
					</div>
					<div className="card-footer">
						<Dropzone
							onDrop={files => {
								files.forEach((file) => {
									addInvoiceDocument(accessToken, {file}, invoice.id);
									setUploadedDocuments(documents => {
										return [
											...documents,
											{
												name: file.name,
												size: formatSize(file.size)
											}
										];
									});
								});
							}}
							accept='.jpg,.jpeg,.png,.pdf'
							disableClick
						>
							{({getRootProps, getInputProps}) => (
								<section>
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<Button
											color="warning"
											outline={true}
											onClick={(e) => {
												e.preventDefault();
											}}
										>
											Feltöltés
										</Button>
									</div>
								</section>
							)}
						</Dropzone>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={toggle}>Bezár</Button>
			</ModalFooter>
		</Modal>
	);
};

const mapStateToProps = ({user}) => {
	return {user};
};

export default connect(mapStateToProps, {addInvoiceDocument})(InvoiceModal);
