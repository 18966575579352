import React, {useEffect, useState} from 'react';
import {connect, useSelector, useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form'
import {HTTP_STATUS, useApi} from '../../hooks/useApi';
import {USER_LOG_OUT} from '../../constant/actionTypes';
import {toast} from 'react-toastify';
import ErrorSpan from '../../components/form/errorSpan';

const PartnerForm = ({partner, submitFunction, isLoading, isCreate}) => {
	const {register, handleSubmit, errors, setValue} = useForm();
	const [get] = useApi();
	const dispatch = useDispatch();

	const accessToken = useSelector(content => content.user.accessToken);

	const [countries, setCountries] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [countriesIsLoading, setCountriesIsLoading] = useState(false);

	const [counties, setCounties] = useState(null);
	const [selectedCounty, setSelectedCounty] = useState(null);
	const [countiesIsLoading, setCountiesIsLoading] = useState(false);

	const [cities, setCities] = useState(null);
	const [citiesIsLoading, setCitiesIsLoading] = useState(false);

	// Countries
	useEffect(() => {
		if (!partner || partner.address) {
			setCountriesIsLoading(true);
			get(`/api/v1/geonames/countries`, accessToken)
				.then(response => {
					setCountriesIsLoading(false);
					setCountries(response.data);

					if (!isCreate) {
						setSelectedCountry(partner.address.geoname.country.iso);
						setValue('address_country', partner.address.geoname.country.iso);
					}
				})
				.catch(err => {
					setCountriesIsLoading(false);
					switch (err.response.status) {
						case HTTP_STATUS.UNAUTHORIZED: {
							dispatch({type: USER_LOG_OUT});
							toast.error('Érvénytelen felhasználó!');
							break;
						}
						default: {
							toast.error('Az országok nem találhatók!');
							break;
						}
					}
				});
		}
		// eslint-disable-next-line
	}, [partner]);

	// Counties
	useEffect(() => {
		if (selectedCountry) {
			setCountiesIsLoading(true);
			get(`/api/v1/geonames/country/${selectedCountry}/counties`, accessToken)
				.then(response => {
					setCountiesIsLoading(false);
					setCounties(response.data);

					if (!isCreate) {
						setSelectedCounty(partner.address.geoname.admin1.id);
						setValue('address_county', partner.address.geoname.admin1.id);
					}
				})
				.catch(err => {
					setCountiesIsLoading(false);
					switch (err.response.status) {
						case HTTP_STATUS.UNAUTHORIZED: {
							dispatch({type: USER_LOG_OUT});
							toast.error('Érvénytelen felhasználó!');
							break;
						}
						default: {
							toast.error('A megye / állam nem található!');
							break;
						}
					}
				});
		}
		// eslint-disable-next-line
	}, [selectedCountry]);

	// Cities
	useEffect(() => {
		if (selectedCounty) {
			setCitiesIsLoading(true);
			get(`/api/v1/geonames/county/${selectedCounty}/cities`, accessToken)
				.then(response => {
					setCitiesIsLoading(false);
					setCities(response.data);

					if (!isCreate) {
						setValue('address_city', partner.address.geoname.id);
					}
				})
				.catch(err => {
					setCitiesIsLoading(false);
					switch (err.response.status) {
						case HTTP_STATUS.UNAUTHORIZED: {
							dispatch({type: USER_LOG_OUT});
							toast.error('Érvénytelen felhasználó!');
							break;
						}
						default: {
							//history.push(`${process.env.PUBLIC_URL}/partners`);
							toast.error('A település nem található!');
							break;
						}
					}
				});
		}
		// eslint-disable-next-line
	}, [selectedCounty]);

	const onSubmit = data => {
		if (data !== '') {
			submitFunction(data);
		} else {
			errors.showMessages();
		}
	};

	return (
		<form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
			<div className="card card-absolute">
				<div className="card-header bg-secondary">
					<h5>Partner adatai</h5>
				</div>
				<div className="card-body">
					<div className="form-row">
						<div className="col-md-3 mb-3">
							<label htmlFor="name">Név</label>
							<input
								className="form-control"
								name="name"
								id="name"
								type="text"
								placeholder="Név"
								defaultValue={(partner && partner.name) || ''}
								ref={register({required: true})}
							/>
							<ErrorSpan>{errors.name && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-3 mb-3">
							<label htmlFor="vat_number">Adószám</label>
							<input
								className="form-control"
								name="vat_number"
								id="vat_number"
								type="text"
								placeholder="Adószám"
								defaultValue={(partner && partner.vat_number) || ''}
								ref={register({required: false})}
							/>
						</div>
						<div className="col-md-3 mb-3">
							<label htmlFor="phone">Telefonszám</label>
							<input
								className="form-control"
								name="phone"
								id="phone"
								type="text"
								placeholder="Telefonszám"
								defaultValue={(partner && partner.phone) || ''}
								ref={register({required: false})}
							/>
						</div>
						<div className="col-md-3 mb-3">
							<label htmlFor="email">E-mail cím</label>
							<input
								className="form-control"
								name="email"
								id="email"
								type="email"
								placeholder="E-mail cím"
								defaultValue={(partner && partner.email) || ''}
								ref={register({required: true})}
							/>
							<ErrorSpan>{errors.email && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
					</div>
				</div>
			</div>
			<div className="card card-absolute">
				<div className="card-header bg-secondary">
					<h5>Cím adatok</h5>
				</div>
				<div className="card-body">
					<div className="form-row">
						<div className="col-md-4 mb-3">
							<label htmlFor="address_country">Ország</label>
							{countriesIsLoading ? <h6><i className="fa fa-spinner fa-spin"/>&nbsp;Betöltés</h6> :
								<select
									className="form-control"
									name="address_country"
									id="address_country"
									ref={register({required: true})}
									onChange={e => {
										setSelectedCountry(e.target.value);
									}}
								>
									<option value="">Válassz</option>
									{countries !== null && countries.map((country) => {
										return <option value={country.iso} key={`country-${country.iso}`}>{country.name}</option>;
									})}
								</select>
							}
							<ErrorSpan>{errors.address_country && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-4 mb-3">
							<label htmlFor="address_county">Megye / Állam</label>
							{countries !== null && (countiesIsLoading || counties !== null) ?
								countiesIsLoading ? <h6><i className="fa fa-spinner fa-spin"/>&nbsp;Betöltés</h6> :
									<select
										className="form-control"
										name="address_county"
										id="address_county"
										ref={register({required: true})}
										onChange={e => {
											setSelectedCounty(e.target.value);
										}}
									>
										<option value="">Válassz</option>
										{counties !== null && counties.map((county) => {
											return <option value={county.id} key={`county-${county.id}`}>{county.name}</option>;
										})}
									</select>
								: ''}
							<ErrorSpan>{errors.address_county && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-4 mb-3">
							<label htmlFor="address_city">Település</label>
							{counties !== null && (citiesIsLoading || cities !== null) ?
								citiesIsLoading ? <h6><i className="fa fa-spinner fa-spin"/>&nbsp;Betöltés</h6> :
									<select
										className="form-control"
										name="address_city"
										id="address_city"
										ref={register({required: true})}
									>
										<option value="">Válassz</option>
										{cities !== null && cities.map((city) => {
											return <option value={city.id} key={`city-${city.id}`}>{city.name}</option>;
										})}
									</select>
								: ''}
							<ErrorSpan>{errors.address_city && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-2 mb-3">
							<label htmlFor="address_postal_code">Irányítószám</label>
							<input
								className="form-control"
								name="address_postal_code"
								id="address_postal_code"
								type="text"
								placeholder="Irányítószám"
								defaultValue={(partner && partner.address && partner.address.postal_code) || ''}
								ref={register({required: true})}
							/>
							<ErrorSpan>{errors.address_postal_code && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-6 mb-3">
							<label htmlFor="address_street_name">Közterület neve</label>
							<input
								className="form-control"
								name="address_street_name"
								id="address_street_name"
								type="text"
								placeholder="Közterület neve"
								defaultValue={(partner && partner.address && partner.address.street_name) || ''}
								ref={register({required: true})}
							/>
							<ErrorSpan>{errors.address_street_name && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-2 mb-3">
							<label htmlFor="address_street_type">Közterület jellege (pl. utca, tér)</label>
							<input
								className="form-control"
								name="address_street_type"
								id="address_street_type"
								type="text"
								placeholder="Közterület jellege"
								defaultValue={(partner && partner.address && partner.address.street_type) || ''}
								ref={register({required: true})}
							/>
							<ErrorSpan>{errors.address_street_type && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-2 mb-3">
							<label htmlFor="address_house_number">Házszám</label>
							<input
								className="form-control"
								name="address_house_number"
								id="address_house_number"
								type="text"
								placeholder="Házszám"
								defaultValue={(partner && partner.address && partner.address.house_number) || ''}
								ref={register({required: true})}
							/>
							<ErrorSpan>{errors.address_house_number && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
					</div>
				</div>
			</div>
			<div className="card card-absolute">
				<div className="card-header bg-secondary">
					<h5>Banki adatok</h5>
				</div>
				<div className="card-body">
					<div className="form-row">
						<div className="col-md-4 mb-3">
							<label htmlFor="bank_account_number">Bankszámlaszám</label>
							<input
								className="form-control"
								name="bank_account_number"
								id="bank_account_number"
								type="text"
								placeholder="Bankszámlaszám"
								defaultValue={(partner && partner.bank_account_number) || ''}
								ref={register({required: false})}
							/>
						</div>
						<div className="col-md-4 mb-3">
							<label htmlFor="bank_iban">IBAN</label>
							<input
								className="form-control"
								name="bank_iban"
								id="bank_iban"
								type="text"
								placeholder="IBAN"
								defaultValue={(partner && partner.bank_iban) || ''}
								ref={register({required: false})}
							/>
						</div>
						<div className="col-md-4 mb-3">
							<label htmlFor="bank_swift">SWIFT kód</label>
							<input
								className="form-control"
								name="bank_swift"
								id="bank_swift"
								type="text"
								placeholder="SWIFT kód"
								defaultValue={(partner && partner.bank_swift) || ''}
								ref={register({required: false})}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="form-row">
				<div className="col-12 mb-3">
					<button className="btn btn-success" type="submit">
						{isLoading ? <><i className="fa fa-spinner fa-spin"/>&nbsp;</> : ''}{isCreate ? 'Létrehozás' : 'Módosítás'}
					</button>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = ({user}) => {
	return {user};
};

export default connect(mapStateToProps)(PartnerForm);
