import {toast} from 'react-toastify';
import {HTTP_STATUS, useApi} from '../hooks/useApi';
import {
	USER_LOG_OUT,
	ADD_INVOICE_DOCUMENT,
	CREATE_INVOICE,
	DELETE_INVOICE
} from '../constant/actionTypes';

// eslint-disable-next-line
const [get, post, remove, postMultipartFormFile] = useApi();

export const createInvoice = (accessToken, data) => dispatch => {
	return new Promise((resolve, reject) => {
		post('/api/v1/billing/invoices/create', accessToken, data)
			.then((response) => {
				dispatch({type: CREATE_INVOICE, payload: response.data});
				toast.success('A számla sikeresen hozzáadva!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A számla hozzáadása sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};

export const addInvoiceDocument = (accessToken, file, invoiceId = null) => dispatch => {
	return new Promise((resolve, reject) => {
		postMultipartFormFile('/api/v1/billing/invoices/create-document', accessToken, file.file, {invoiceId})
			.then((response) => {
				dispatch({type: ADD_INVOICE_DOCUMENT, payload: response.data.id});
				toast.success('A dokumentum sikeresen hozzáadva!');
				resolve(response.data.id);
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A dokumentum hozzáadása sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
}

export const deleteInvoice = (id, accessToken) => dispatch => {
	return new Promise((resolve, reject) => {
		remove(`/api/v1/billing/invoices/delete/${id}`, accessToken)
			.then((response) => {
				dispatch({type: DELETE_INVOICE, payload: id});
				toast.success('A számla sikeresen törölve!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A számla törlése sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};
