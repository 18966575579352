import {combineReducers} from 'redux';

import user from './user.reducer';
import partners from './partner.reducer';
import receipts from './receipt.reducer';

const reducers = combineReducers({
	user,
	partners,
	receipts
});

export default reducers;
