import React, {Fragment} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';
import {connect} from 'react-redux';

import * as Sentry from '@sentry/browser';

import './index.scss';

import App from './components/app';

import {getUserProfile} from './actions/user.action';

import Login from './pages/auth/login';
import Dashboard from './components/dashboard/';

// Invoices (Income)
import InvoiceIncomeList from './pages/invoice/income';
import InvoiceIncomeCreate from './pages/invoice/income/create';

// Invoices (Outlay)
import InvoiceOutlayList from './pages/invoice/outlay';
import InvoiceOutlayCreate from './pages/invoice/outlay/create';

// Partners
import PartnerList from './pages/partner/list';
import PartnerCreate from './pages/partner/create';
import PartnerUpdate from './pages/partner/update';

// Receipts
import ReceiptList from './pages/receipt/list';
//import PartnerCreate from './pages/partner/create';

import Samplepage from './components/sample/samplepage';
import SupportTicket from './components/support-ticket/supportTicket';

const Root = ({user}) => {
	if (user !== undefined && user !== null) {
		Sentry.setUser({
			name: user.firstName + ' ' + user.lastName,
			email: user.email
		});
	}

	return (
		<div className='app'>
			<BrowserRouter basename={'/'}>
				<ScrollContext>
					<Switch>
						<Route path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
						{user !== undefined && user !== null && user.isLoggedIn ?
							<Fragment>
								<App>
									<Route exact path={`${process.env.PUBLIC_URL}/`} component={Dashboard}/>

									{/*Invoices (Income)*/}
									<Route exact path={`${process.env.PUBLIC_URL}/invoices`} component={InvoiceIncomeList}/>
									<Route exact path={`${process.env.PUBLIC_URL}/invoices/create`} component={InvoiceIncomeCreate}/>

									{/*Invoices (Outlay)*/}
									<Route exact path={`${process.env.PUBLIC_URL}/outlays`} component={InvoiceOutlayList}/>
									<Route exact path={`${process.env.PUBLIC_URL}/outlays/create`} component={InvoiceOutlayCreate}/>

									{/*Partners*/}
									<Route exact path={`${process.env.PUBLIC_URL}/partners`} component={PartnerList}/>
									<Route exact path={`${process.env.PUBLIC_URL}/partners/create`} component={PartnerCreate}/>
									<Route exact path={`${process.env.PUBLIC_URL}/partners/update/:id`} component={PartnerUpdate}/>

									{/*Receipts*/}
									<Route exact path={`${process.env.PUBLIC_URL}/receipts`} component={ReceiptList}/>

									{/*----------*/}
									<Route path={`${process.env.PUBLIC_URL}/sample/samplepage`} component={Samplepage}/>
									<Route path={`${process.env.PUBLIC_URL}/support-ticket/supportTicket`} component={SupportTicket}/>
								</App>
							</Fragment>
							:
							<Redirect to={`${process.env.PUBLIC_URL}/login`}/>
						}
					</Switch>
				</ScrollContext>
			</BrowserRouter>
		</div>
	);
};

const mapStateToProps = ({user}) => {
	return {user};
};

export default connect(mapStateToProps, {getUserProfile})(Root);
