// User
export const USER_LOG_IN = 'USER_LOG_IN';
export const USER_LOG_IN_REJECTED = 'USER_LOG_IN_REJECTED';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_INFO_ARRIVED = 'USER_INFO_ARRIVED';
export const USER_LOG_OUT = 'USER_LOG_OUT';

// Partner
export const FETCH_PARTNERS = 'FETCH_PARTNERS';
export const FETCH_PARTNERS_REJECTED = 'FETCH_PARTNERS_REJECTED';
export const FETCH_PARTNERS_FULFILLED = 'FETCH_PARTNERS_FULFILLED';
export const CREATE_PARTNER = 'CREATE_PARTNER';
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const DELETE_PARTNER = 'DELETE_PARTNER';

// Invoice (Income)
export const ADD_INVOICE_DOCUMENT = 'ADD_INVOICE_DOCUMENT';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const DELETE_INVOICE = 'DELETE_INVOICE';

// Invoice (Outlay)
export const ADD_OUTLAY_DOCUMENT = 'ADD_OUTLAY_DOCUMENT';
export const CREATE_OUTLAY = 'CREATE_OUTLAY';
export const DELETE_OUTLAY = 'DELETE_OUTLAY';

// Receipts
export const FETCH_RECEIPTS = 'FETCH_RECEIPTS';
export const FETCH_RECEIPTS_REJECTED = 'FETCH_RECEIPTS_REJECTED';
export const FETCH_RECEIPTS_FULFILLED = 'FETCH_RECEIPTS_FULFILLED';
export const CREATE_RECEIPT = 'CREATE_RECEIPT';
export const DELETE_RECEIPT = 'DELETE_RECEIPT';
