import React, {Fragment, useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {Button} from 'reactstrap';
import {confirmAlert} from 'react-confirm-alert';
import Moment from 'react-moment';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {fetchReceipts, deleteReceipt} from '../../actions/receipt.action';

import Breadcrumb from '../../components/common/breadcrumb';

import ReceiptModal from './modal';

const ReceiptList = ({receipts, fetchReceipts, deleteReceipt}) => {
	const accessToken = useSelector(content => content.user.accessToken);

	const [modalStates, setModalStates] = useState({});

	useEffect(() => {
		fetchReceipts(accessToken);

		if (receipts.data !== null) {
			// Preload invoice modal states
			if (Array.isArray(receipts.data)) {
				for (let receipt of receipts.data) {
					setModalStates(states => {
						return {
							...states,
							[receipt.id]: false
						};
					});
				}
			}
		}
		// eslint-disable-next-line
	}, []);

	const openModal = (receiptId) => {
		setModalStates({
			...modalStates,
			[receiptId]: true
		});
	};

	const toggleModal = (receiptId) => {
		setModalStates({
			...modalStates,
			[receiptId]: !modalStates[receiptId]
		});
	};

	return (
		<Fragment>
			<Breadcrumb title="Megtekintés" parent="Nyugták"/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<div className="pull-right">
									<Button type="a" color="success" size="sm" title="Új partner" href={`${process.env.PUBLIC_URL}/receipts/create`}>
										<i className="fa fa-plus fa-fw"/>
									</Button>
								</div>
								<h5>Nyugták</h5>
							</div>
							<div className="card-body">
								<table className="table table-hover">
									<thead>
										<tr>
											<th>Használatbavétel</th>
											<th>Használat befejezése</th>
											<th>Számjel</th>
											<th>Sorszám (-tól)</th>
											<th>Sorszám (-ig)</th>
											<th className="text-center">Műveletek</th>
										</tr>
									</thead>
									<tbody>
										{receipts.fetching && !receipts.fetched ? <tr>
												<td colSpan={6} className="text-center">
													<h5><i className="fa fa-spinner fa-spin"/>&nbsp;Betöltés</h5>
												</td>
											</tr> :
											receipts.data !== null ?
												receipts.data.map((receipt, idx) => <tr key={idx}>
														<td>{receipt.date_from ? <Moment format='YYYY.MM.DD.'>{receipt.date_from}</Moment> : <em>--- Nincs megadva ---</em>}</td>
														<td>{receipt.date_to ? <Moment format='YYYY.MM.DD.'>{receipt.date_to}</Moment> : <em>--- Nincs megadva ---</em>}</td>
														<td>{receipt.prefix}</td>
														<td>{receipt.number_from}</td>
														<td>{receipt.number_to}</td>
														<td className="text-center">
															<Button
																color="secondary"
																size="sm"
																title="Megtekint"
																onClick={() => {
																	openModal(receipt.id);
																}}
															>
																<i className="fa fa-eye fa-fw"/>
															</Button>
															<ReceiptModal
																receipt={receipt}
																isOpen={modalStates[receipt.id]}
																toggle={() => {
																	toggleModal(receipt.id);
																}}
															/>
															&nbsp;
															<Button
																color="danger"
																size="sm"
																title="Törlés"
																onClick={() => {
																	confirmAlert({
																		title: 'Megerősítés',
																		message: `Biztosan törlöd a nyugtát? (${receipt.prefix}${receipt.number_from} - ${receipt.prefix}${receipt.number_to})`,
																		buttons: [
																			{
																				label: 'Igen',
																				onClick: () => {
																					deleteReceipt(receipt.id, accessToken);
																				}
																			},
																			{
																				label: 'Nem'
																			}
																		]
																	});
																}}
															>
																<i className="fa fa-trash fa-fw"/>
															</Button>
														</td>
													</tr>
												) : <tr>
													<td colSpan={6} className="text-center">
														<h5>Nincsenek nyugták</h5>
													</td>
												</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = ({receipts}) => {
	return {receipts};
};

export default connect(mapStateToProps, {fetchReceipts, deleteReceipt})(ReceiptList);
