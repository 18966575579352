import React, {useState, useEffect} from 'react';
import Currency from 'react-currency-formatter';
import Moment from 'react-moment';
import {Button} from 'reactstrap';
import {confirmAlert} from 'react-confirm-alert';

import InvoiceModal from './modal';
import PaymentMethod from '../../components/invoice/paymentMethod';

const InvoiceList = ({invoices, isIncome, isLoading}) => {
	const [modalStates, setModalStates] = useState({});

	useEffect(() => {
		// Preload invoice modal states
		if (Array.isArray(invoices)) {
			for (let invoice of invoices) {
				setModalStates(states => {
					return {
						...states,
						[invoice.id]: false
					};
				});
			}
		}
		// eslint-disable-next-line
	}, []);

	const openModal = (invoiceId) => {
		setModalStates({
			...modalStates,
			[invoiceId]: true
		});
	};

	const toggleModal = (invoiceId) => {
		setModalStates({
			...modalStates,
			[invoiceId]: !modalStates[invoiceId]
		});
	};

	return (
		<table className="table table-hover">
			<thead>
				<tr>
					<th className="text-center">Sorszám</th>
					<th>{isIncome ? 'Vevő' : 'Eladó'}</th>
					<th className="text-center">Számla kelte</th>
					<th className="text-center">Teljesítés kelte</th>
					<th className="text-center">Fizetési határidő</th>
					<th className="text-center">Fizetési mód</th>
					<th className="text-center">Nettó</th>
					<th className="text-center">Bruttó</th>
					<th>Műveletek</th>
				</tr>
			</thead>
			<tbody>
				{invoices !== null && invoices !== undefined ?
					invoices.map((invoice, idx) => <tr key={idx}>
							<td className="text-center">{invoice.invoice_number}</td>
							<td>{isIncome ? invoice.client_name : invoice.seller_name}</td>
							<td className="text-center"><Moment format='YYYY.MM.DD.'>{invoice.date}</Moment></td>
							<td className="text-center"><Moment format='YYYY.MM.DD.'>{invoice.fulfillment_date}</Moment></td>
							<td className="text-center"><Moment format='YYYY.MM.DD.'>{invoice.due_date}</Moment></td>
							<td className="text-center"><PaymentMethod method={invoice.payment_method}/></td>
							<td className="text-center">
								<Currency
									quantity={invoice.sub_total}
									currency={invoice.currency}
								/>
							</td>
							<td className="text-center">
								<Currency
									quantity={invoice.total}
									currency={invoice.currency}
								/>
							</td>
							<td className="text-center">
								<Button
									color="secondary"
									size="sm"
									title="Megtekint"
									onClick={() => {
										openModal(invoice.id);
									}}
								>
									<i className="fa fa-eye fa-fw"/>
								</Button>
								<InvoiceModal
									invoice={invoice}
									isOpen={modalStates[invoice.id]}
									toggle={() => {
										toggleModal(invoice.id);
									}}
									isIncome={isIncome}
								/>
								&nbsp;
								<Button
									color="danger"
									size="sm"
									title="Törlés"
									onClick={() => {
										confirmAlert({
											title: 'Megerősítés',
											message: `Biztosan törlöd a számlát? (${invoice.invoice_number})`,
											buttons: [
												{
													label: 'Igen',
													onClick: () => {
													}
												},
												{
													label: 'Nem'
												}
											]
										});
									}}
								>
									<i className="fa fa-trash fa-fw"/>
								</Button>
							</td>
						</tr>
					) : <tr>
						<td colSpan={9} className="text-center">
							{isLoading ? <h5><i className="fa fa-spinner fa-spin"/>&nbsp;Betöltés</h5> : <h5>Nincsenek számlák</h5>}
						</td>
					</tr>}
			</tbody>
		</table>
	);
};

export default InvoiceList;
