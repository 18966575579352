import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

import reducers from '../reducers/index';

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch(e){
        return undefined;
    }
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user']
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, compose(
    applyMiddleware(thunkMiddleware, logger),

    window.devToolsExtension ? window.devToolsExtension() : function (f) {
        return f;
    }
));

export const persistor = persistStore(store);

// eslint-disable-next-line
const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    saveToLocalStorage(state);
});


export default store;