import {PURGE} from 'redux-persist';
import {apiResponse} from './initialState';

import {
	FETCH_PARTNERS,
	FETCH_PARTNERS_REJECTED,
	FETCH_PARTNERS_FULFILLED,
	CREATE_PARTNER,
	UPDATE_PARTNER,
	DELETE_PARTNER
} from '../constant/actionTypes';

export default (state = apiResponse, action) => {
	switch (action.type) {
		case FETCH_PARTNERS: {
			return {
				...state,
				fetching: true
			};
		}

		case FETCH_PARTNERS_REJECTED: {
			return {
				...state,
				fetching: false,
				error: action.payload
			};
		}

		case FETCH_PARTNERS_FULFILLED: {
			return {
				...state,
				error: null,
				fetching: false,
				fetched: true,
				data: action.payload
			};
		}

		case CREATE_PARTNER: {
			return {
				...state,
				data: [
					...state.data,
					action.payload
				]
			};
		}

		case UPDATE_PARTNER: {
			const {id} = action.payload;
			const partners = [...state.data];
			const partnerToUpdate = partners.findIndex(partner => partner.id === id);
			partners[partnerToUpdate] = action.payload;

			return {
				...state,
				data: partners,
			};
		}

		case DELETE_PARTNER: {
			return {
				...state,
				data: state.data.filter(partner => partner.id !== action.payload),
			}
		}

		case PURGE: {
			return {};
		}

		default: {
			return state;
		}
	}
}
