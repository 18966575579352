import axios from 'axios';
import * as Sentry from '@sentry/browser';

export const useApi = () => {
	const defaultHeaders = {
		'crossDomain': true,
		'Content-Type': 'application/json',
	};

	const get = (url, accessToken, extraParams = {}) => {
		let headers = {...defaultHeaders, ...extraParams};

		if (accessToken !== null) {
			headers = {
				...headers,
				'Authorization': getAccessTokenString(accessToken)
			};
		}

		return new Promise((resolve, reject) => {
			axios
				.get(process.env.REACT_APP_API_SERVER_HOST + url, {
					headers: headers
				})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					Sentry.withScope((scope) => {
						scope.setExtras({error, errorResponse: error.response});
						const eventId = Sentry.captureException(error);

						error = {...error, sentryEventId: eventId};
					});
					reject(error);
				});
		});
	};

	const post = (url, accessToken, data, extraParams = {}) => {
		let headers = {...defaultHeaders, ...extraParams};

		if (accessToken !== null) {
			headers = {
				...headers,
				'Authorization': getAccessTokenString(accessToken)
			};
		}

		return new Promise((resolve, reject) => {
			axios
				.post(process.env.REACT_APP_API_SERVER_HOST + url, data, {
					headers: headers
				})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					Sentry.withScope((scope) => {
						scope.setExtras({error, errorResponse: error.response});
						const eventId = Sentry.captureException(error);

						error = {...error, sentryEventId: eventId};
					});
					reject(error);
				});
		});
	};

	const postMultipartFormFile = (url, accessToken, file, extraData, extraParams = {}) => {
		let data = new FormData();
		data.append('file', file);

		for (let dataIdx in extraData) {
			data.append(dataIdx, extraData[dataIdx]);
		}

		return post(url, accessToken, data, extraParams);
	};

	const remove = (url, accessToken, extraParams = {}) => {
		let headers = {...defaultHeaders, ...extraParams};

		if (accessToken !== null) {
			headers = {
				...headers,
				'Authorization': getAccessTokenString(accessToken)
			};
		}

		return new Promise((resolve, reject) => {
			axios
				.delete(process.env.REACT_APP_API_SERVER_HOST + url, {
					headers: headers
				})
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					Sentry.withScope((scope) => {
						scope.setExtras({error, errorResponse: error.response});
						const eventId = Sentry.captureException(error);

						error = {...error, sentryEventId: eventId};
					});
					reject(error);
				});
		});
	};

	/**
	 * @param {string} token
	 *
	 * @returns {string}
	 */
	const getAccessTokenString = token => 'Bearer ' + token;

	return [get, post, remove, postMultipartFormFile];
};

export const HTTP_STATUS = {
	OK: 200,
	CREATED: 201,
	NO_CONTENT: 204,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	METHOD_NOT_ALLOWED: 405,
	CONFLICT: 409,
	INTERNAL_SERVER_ERROR: 500
};
