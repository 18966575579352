import {toast} from 'react-toastify';
import {PURGE} from 'redux-persist';
import {HTTP_STATUS, useApi} from '../hooks/useApi';
import {
	USER_LOG_IN,
	USER_LOGGED_IN,
	USER_INFO_ARRIVED,
	USER_LOG_OUT,
	USER_LOG_IN_REJECTED,
} from '../constant/actionTypes';

const [get, post] = useApi();

export const login = (data) => dispatch => {
	dispatch({type: USER_LOG_IN});

	return new Promise((resolve, reject) => {
		post('/oauth/v2/token', null, {
			grant_type: 'password',
			client_id: process.env.REACT_APP_API_CLIENT_ID,
			client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
			username: data.email,
			password: data.password
		})
			.then((response) => {
				dispatch({type: USER_LOGGED_IN, payload: response.data});
				localStorage.setItem('accessToken', response.data.access_token);

				get('/api/v1/users/me', response.data.access_token)
					.then((response) => {
						dispatch({type: USER_INFO_ARRIVED, payload: response.data});

						resolve();
					})
					.catch((error) => {
						dispatch({type: USER_LOG_OUT, payload: error});
						toast.error('Érvénytelen felhasználó!');
						console.warn(error);

						reject();
					});
			})
			.catch((error) => {
				if (error.response !== undefined && error.response !== null) {
					switch (error.response.status) {
						case HTTP_STATUS.BAD_REQUEST:
							toast.error('Hibás felhasználónév és/vagy jelszó!');
							break;
						default:
							toast.error('Ismeretlen hiba!');
							break;
					}

					dispatch({type: USER_LOG_IN_REJECTED, payload: error.response.data});
				}

				// TODO: Logolás
				console.warn(error);

				reject();
			});
	});
};

export const getUserProfile = () => dispatch => {
	get('/api/v1/users/me')
		.then((response) => {
			dispatch({type: USER_INFO_ARRIVED, payload: response.data});
		})
		.catch((error) => {
			dispatch({type: USER_LOG_OUT, payload: error});
			toast.error('Érvénytelen felhasználó!');
			console.warn(error);
		});
};

export const logout = () => dispatch => {
	dispatch({type: USER_LOG_OUT});
	dispatch({type: PURGE, key: 'root', result: () => null});
};
