import React from 'react';
import ReactDOM from 'react-dom';
import store, {persistor} from './store/index';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';

import Root from './app';
import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/browser';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_HOST,
	environment: process.env.NODE_ENV
});

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Root/>
		</PersistGate>
	</Provider>, document.getElementById('root'));

serviceWorker.unregister();
