const PaymentMethod = ({method}) => {
	switch (method) {
		case 'BANK_TRANSFER':
			return 'Átutalás';
		case 'CASH':
			return 'Készpénz';
		case 'PAYPAL':
			return 'PayPal';
		case 'CREDIT_CARD':
			return 'Bankkártya';
		default:
			return 'Nincs megadva';
	}
};

export default PaymentMethod;
