import React, {Fragment, useState} from 'react';
import {connect, useSelector} from 'react-redux';

import PartnerForm from './form';
import Breadcrumb from '../../components/common/breadcrumb';

import {createPartner} from '../../actions/partner.action';

const PartnerCreate = ({history, createPartner}) => {
	const accessToken = useSelector(content => content.user.accessToken);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Fragment>
			<Breadcrumb title="Létrehozás" parent="Partnerek"/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<PartnerForm
							isLoading={isLoading}
							isCreate={true}
							submitFunction={async (data) => {
								setIsLoading(true);
								await createPartner(accessToken, data)
									.then(() => {
										setIsLoading(false);
										history.push(`${process.env.PUBLIC_URL}/partners`);
									})
									.catch(err => {
										setIsLoading(false);
									});
							}}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default connect(null, {createPartner})(PartnerCreate);
