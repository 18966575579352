import {toast} from 'react-toastify';
import {HTTP_STATUS, useApi} from '../hooks/useApi';
import {
	USER_LOG_OUT,
	FETCH_RECEIPTS,
	FETCH_RECEIPTS_REJECTED,
	FETCH_RECEIPTS_FULFILLED,
	CREATE_RECEIPT,
	DELETE_RECEIPT
} from '../constant/actionTypes';

const [get, post, remove] = useApi();

export const fetchReceipts = (accessToken) => dispatch => {
	dispatch({type: FETCH_RECEIPTS});
	get('/api/v1/billing/receipts/', accessToken)
		.then((response) => {
			dispatch({type: FETCH_RECEIPTS_FULFILLED, payload: response.data});
		})
		.catch((err) => {
			switch (err.response.status) {
				case HTTP_STATUS.UNAUTHORIZED: {
					dispatch({type: USER_LOG_OUT});
					toast.error('Érvénytelen felhasználó!');
					break;
				}
				default: {
					dispatch({type: FETCH_RECEIPTS_REJECTED, payload: err});
					toast.error('A nyugták betöltése sikertelen!');
					break;
				}
			}
		});
};

export const createReceipt = (accessToken, data) => dispatch => {
	return new Promise((resolve, reject) => {
		post('/api/v1/billing/receipts/create', accessToken, data)
			.then((response) => {
				dispatch({type: CREATE_RECEIPT, payload: response.data});
				toast.success('A nyugta sikeresen hozzáadva!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A nyugta hozzáadása sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};

export const updateReceipt = (data, accessToken) => dispatch => {
	return new Promise((resolve, reject) => {
		post('/api/v1/billing/receipts/update', accessToken, data)
			.then(() => {
				toast.success('A nyugta sikeresen módosítva!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A nyugta módosítása sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};

export const updateReceiptItem = (data, accessToken) => dispatch => {
	return new Promise((resolve, reject) => {
		post('/api/v1/billing/receipts/item/update', accessToken, data)
			.then(() => {
				toast.success('A nyugta sikeresen módosítva!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A nyugta módosítása sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};

export const deleteReceipt = (id, accessToken) => dispatch => {
	return new Promise((resolve, reject) => {
		remove(`/api/v1/billing/receipts/delete/${id}`, accessToken)
			.then((response) => {
				dispatch({type: DELETE_RECEIPT, payload: id});
				toast.success('A nyugta sikeresen törölve!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A nyugta törlése sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};
