import React, {useState, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import Moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import ErrorSpan from '../../components/form/errorSpan';

import {updateReceipt, updateReceiptItem} from '../../actions/receipt.action';

const ReceiptModal = ({receipt, isOpen, toggle, isCreate, updateReceipt, updateReceiptItem}) => {
	const {register, handleSubmit, errors, control} = useForm();

	const accessToken = useSelector(content => content.user.accessToken);

	const [receiptItems, setReceiptItems] = useState([]);

	useEffect(() => {
		if (receipt !== null) {
			setReceiptItems(receipt.items);
		}
	}, [receipt]);

	const onSubmit = data => {
		if (data !== '') {
			const receiptData = {
				id: receipt.id,
				prefix: data.prefix,
				date_from: data.date_from ? Moment(data.date_from).format('YYYY-MM-DD') : null,
				date_to: data.date_to ? Moment(data.date_to).format('YYYY-MM-DD') : null,
				items: receiptItems,
			};

			console.log(receiptData);

			updateReceipt(receiptData);
		} else {
			errors.showMessages();
		}
	};

	const handleReceiptItemValueChange = e => {
		const items = [...receiptItems];

		items[e.target.dataset.index][e.target.dataset.field] = parseInt(e.target.value);

		setReceiptItems(items);
	};

	const handleReceiptItemDateValueChange = (date, idx) => {
		const items = [...receiptItems];

		items[idx]['date'] = date;

		setReceiptItems(items);

		return date;
	};

	return (
		<Modal isOpen={isOpen} toggle={toggle} centered={true} size="lg">
			<form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
				<ModalHeader toggle={toggle}>
					{`${receipt.prefix}${receipt.number_from} - ${receipt.prefix}${receipt.number_to}`}
				</ModalHeader>
				<ModalBody>
					<div className="card card-absolute border">
						<div className="card-header bg-secondary">
							<h5>Nyugta adatai</h5>
						</div>
						<div className="card-body">
							<div className="form-row">
								<div className="col-md-6 mb-3">
									<label htmlFor="prefix">Számjel</label>
									<input
										className="form-control"
										name="prefix"
										id="prefix"
										type="text"
										placeholder="Számjel"
										defaultValue={receipt?.prefix || ''}
										ref={register({required: true})}
									/>
									<ErrorSpan>{errors.prefix && 'A mező kitöltése kötelező!'}</ErrorSpan>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6 mb-3">
									<label htmlFor="date_from">Használatbavétel</label>
									<Controller
										as={DatePicker}
										control={control}
										valueName="selected"
										onChange={([selected]) => selected}
										name="date_from"
										id="date_from"
										className="form-control"
										placeholderText="Használatbavétel"
										autoComplete="off"
										dateFormat="yyyy-MM-dd"
										dropdownMode="scroll"
										defaultValue={receipt?.date_from ? Date.parse(receipt.date_from) : ''}
									/>
								</div>
								<div className="col-md-6 mb-3">
									<label htmlFor="date_to">Használat befejezése</label>
									<Controller
										as={DatePicker}
										control={control}
										valueName="selected"
										onChange={([selected]) => selected}
										name="date_to"
										id="date_to"
										className="form-control"
										placeholderText="Használat befejezése"
										autoComplete="off"
										dateFormat="yyyy-MM-dd"
										dropdownMode="scroll"
										defaultValue={receipt?.date_to ? Date.parse(receipt.date_to) : ''}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="card card-absolute border">
						<div className="card-header bg-secondary">
							<h5>Lapok</h5>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-12">
									<table className="table table-hover">
										<thead>
											<tr>
												<th>Sorszám</th>
												<th>Kiállítás dátuma</th>
												<th>Összeg</th>
												<th>Műveletek</th>
											</tr>
										</thead>
										<tbody>
											{receiptItems.map((item, idx) => <tr key={idx}>
												<td>{`${receipt.prefix}${item.number}`}</td>
												<td>
													<Controller
														as={DatePicker}
														control={control}
														valueName="selected"
														onChange={([selected]) => {
															return handleReceiptItemDateValueChange(selected, idx);
														}}
														name={`item_${idx}_date`}
														id={`item_${idx}_date`}
														className="form-control"
														placeholderText="Kiállítás dátuma"
														autoComplete="off"
														dateFormat="yyyy-MM-dd"
														dropdownMode="scroll"
														defaultValue={item.date ? Date.parse(item.date) : ''}
													/>
												</td>
												<td>
													<input
														className="form-control"
														name={`item_${idx}_price`}
														id={`item_${idx}_price`}
														data-index={idx}
														data-field='price'
														type="number"
														placeholder="Összeg"
														ref={register()}
														onChange={handleReceiptItemValueChange}
														defaultValue={item.price ? item.price : ''}
													/>
												</td>
												<td className="text-center">
													<Button color="success" size="sm" title="Mentés" onClick={() => {
														updateReceiptItem({
															...item,
															date: Moment(item.date).format('YYYY-MM-DD')
														}, accessToken)
													}}>
														<i className="fa fa-save fa-fw"/>
													</Button>
												</td>
											</tr>)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button color="success" type="submit">{isCreate ? 'Létrehozás' : 'Módosítás'}</Button>
					<Button color="secondary" onClick={toggle}>Bezár</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

const mapStateToProps = ({user}) => {
	return {user};
};

export default connect(mapStateToProps, {updateReceipt, updateReceiptItem})(ReceiptModal);
