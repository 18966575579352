import React from 'react';

const Footer = props => {
    return (
        <footer className='footer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 footer-copyright'>
                        <p className='mb-0'>
                            Copyright 2018-{(new Date().getFullYear())} © yune.hu Minden jog fenntartva.
                            </p>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;