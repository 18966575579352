import {toast} from 'react-toastify';
import {HTTP_STATUS, useApi} from '../hooks/useApi';
import {
	USER_LOG_OUT,
	ADD_OUTLAY_DOCUMENT,
	CREATE_OUTLAY,
	DELETE_OUTLAY
} from '../constant/actionTypes';

// eslint-disable-next-line
const [get, post, remove, postMultipartFormFile] = useApi();

export const createOutlay = (accessToken, data) => dispatch => {
	return new Promise((resolve, reject) => {
		post('/api/v1/billing/outlays/create', accessToken, data)
			.then((response) => {
				dispatch({type: CREATE_OUTLAY, payload: response.data});
				toast.success('A kiadás sikeresen hozzáadva!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A kiadás hozzáadása sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};

export const addOutlayDocument = (accessToken, file, outlayId = null) => dispatch => {
	return new Promise((resolve, reject) => {
		postMultipartFormFile('/api/v1/billing/outlays/create-document', accessToken, file.file, {outlayId})
			.then((response) => {
				dispatch({type: ADD_OUTLAY_DOCUMENT, payload: response.data.id});
				toast.success('A dokumentum sikeresen hozzáadva!');
				resolve(response.data.id);
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A dokumentum hozzáadása sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
}

export const deleteOutlay = (id, accessToken) => dispatch => {
	return new Promise((resolve, reject) => {
		remove(`/api/v1/billing/outlays/delete/${id}`, accessToken)
			.then((response) => {
				dispatch({type: DELETE_OUTLAY, payload: id});
				toast.success('A kiadás sikeresen törölve!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A kiadás törlése sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};
