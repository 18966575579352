import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import Gravatar from 'react-gravatar';

import {User, Mail, Lock, Settings, LogOut} from 'react-feather';
import {logout} from '../../../actions/user.action';

const UserMenu = ({history, user, logout}) => {
	return (
		<Fragment>
			<li className="onhover-dropdown">
				<div className="media align-items-center">
					<Gravatar
						email={user.email}
						size={50}
						default="identicon"
						className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
						alt="header-user"
					/>
					<div className="dotted-animation">
						<span className="animate-circle" />
						<span className="main-circle" />
					</div>
				</div>
				<ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
					<li>
						<a href={`${process.env.PUBLIC_URL}/#`}>
							<User/>Profil
						</a>
					</li>
					<li>
						<a href={`${process.env.PUBLIC_URL}/#`}>
							<Mail/>Inbox
						</a>
					</li>
					<li>
						<a href={`${process.env.PUBLIC_URL}/#`}>
							<Lock/>Lock Screen
						</a>
					</li>
					<li>
						<a href={`${process.env.PUBLIC_URL}/#`}>
							<Settings/>Settings
						</a>
					</li>
					<li>
						<a
							href={`${process.env.PUBLIC_URL}/#`}
							onClick={() => {
								logout();
								history.push(`${process.env.PUBLIC_URL}/login`);
							}}
						>
							<LogOut/> Kijelentkezés
						</a>
					</li>
				</ul>
			</li>
		</Fragment>
	);
};

const mapStateToProps = ({user}) => {
	return {user};
};

export default withRouter(connect(mapStateToProps, {logout})(UserMenu));
