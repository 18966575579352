import React from 'react';

export const ErrorSpan = ({children, ...rest}) => {
	return (
		<span className="text-danger" {...rest}>
			{children}
		</span>
	);
};

export default ErrorSpan;
