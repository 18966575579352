export const apiResponse = {
	data: [],
	fetching: false,
	fetched: false,
	error: null
};

export const userResponse = {
	fetching: false,
	fetched: false,
	error: null,

	isLoggedIn: null,
	accessToken: null,
	userId: null,
	firstName: null,
	lastName: null,
	email: null
};
