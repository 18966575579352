import React, {Fragment, useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {Button} from 'reactstrap';
import {toast} from 'react-toastify';

import {HTTP_STATUS, useApi} from '../../hooks/useApi';
import Breadcrumb from '../../components/common/breadcrumb';
import { USER_LOG_OUT} from '../../constant/actionTypes';

import InvoiceList from './list';

const IncomeList = ({history}) => {
	const accessToken = useSelector(content => content.user.accessToken);
	const dispatch = useDispatch();
	const [get] = useApi();
	const [isLoading, setIsLoading] = useState(true);
	const [invoices, setInvoices] = useState(null);

	useEffect(() => {
		get('/api/v1/billing/invoices', accessToken)
			.then((response) => {
				setInvoices(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						history.push(`${process.env.PUBLIC_URL}/login`);
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A számlák betöltése sikertelen!');
						break;
					}
				}
			});
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Megtekintés" parent={[{title: 'Bevételek'}, {title: 'Számlák'}]}/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<div className="pull-right">
									<Button type="a" color="success" size="sm" title="Új számla" href={`${process.env.PUBLIC_URL}/invoices/create`}>
										<i className="fa fa-plus fa-fw"/>
									</Button>
									&nbsp;
									<Button color="info" size="sm" title="Frissítés">
										<i className="fa fa-refresh fa-fw"/>
									</Button>
								</div>
								<h5>Számlák</h5>
							</div>
							<div className="card-body">
								<InvoiceList isIncome={true} invoices={invoices} isLoading={isLoading}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default connect(null)(IncomeList);
