import {PURGE} from 'redux-persist';
import {userResponse} from './initialState';

import {
	USER_LOG_IN,
	USER_LOG_IN_REJECTED,
	USER_LOGGED_IN,
	USER_INFO_ARRIVED,
	USER_LOG_OUT,
} from '../constant/actionTypes';

export default (state = userResponse, action) => {
	switch (action.type) {
		case USER_LOG_IN: {
			return {
				...state,
				fetching: true
			};
		}

		case USER_LOG_IN_REJECTED: {
			return {
				...state,
				fetching: false,
				error: action.payload
			};
		}

		case USER_LOGGED_IN: {
			return {
				...state,
				error: null,
				fetching: false,
				fetched: true,
				isLoggedIn: true,
				accessToken: action.payload.access_token
			};
		}

		case USER_INFO_ARRIVED: {
			return {
				...state,
				fetched: true,
				error: null,
				isLoggedIn: true,
				userId: action.payload.id,
				email: action.payload.email,
				firstName: action.payload.firstname,
				lastName: action.payload.lastname
			};
		}

		case USER_LOG_OUT: {
			return {
				...state
			};
		}

		case PURGE: {
			return {};
		}

		default: {
			return state;
		}
	}
}
