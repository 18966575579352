import React, {useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from '../../assets/images/yuneerp.png';

import {login} from '../../actions/user.action';

const Login = ({history, login}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const loginAuth = async () => {
		try {
			setIsLoading(true);
			await login({email, password})
				.then(() => {
					setIsLoading(false);
					history.push(`${process.env.PUBLIC_URL}/`);
				})
				.catch(err => {
					setIsLoading(false);
				});
		} catch (error) {
			setIsLoading(false);
			toast.error('Ismeretlen hiba történt!');
		}
	};

	return (
		<div>
			<div className="page-wrapper">
				<div className="container-fluid p-0">
					<div className="authentication-main">
						<div className="row">
							<div className="col-md-12">
								<div className="auth-innerright">
									<div className="authentication-box">
										<div className="text-center">
											<img src={logo} alt=""/></div>
										<div className="card mt-4">
											<div className="card-body">
												<div className="text-center">
													<h4>Bejelentkezés</h4>
												</div>
												<form className="theme-form">
													<div className="form-group">
														<label className="col-form-label pt-0">E-mail cím</label>
														<input className="form-control" type="email" name="email"
															   value={email}
															   placeholder="E-mail cím"
															   onChange={e => setEmail(e.target.value)}
														/>
													</div>
													<div className="form-group">
														<label className="col-form-label">Jelszó</label>
														<input className="form-control" type="password" name="password"
															   value={password}
															   placeholder="Jelszó"
															   onChange={e => setPassword(e.target.value)}
														/>
													</div>
													<div className="form-group form-row mt-3 mb-0">
														<button
															className="btn btn-primary btn-block"
															type="button"
															onClick={() => loginAuth()}
															disabled={isLoading}
														>
															{isLoading ? <><i className="fa fa-spinner fa-spin"/>&nbsp;</> : ''}Bejelentkezés
														</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<ToastContainer/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({user}) => {
	return {user};
};

export default withRouter(connect(mapStateToProps, {login})(Login));
