import {
	Home,
	CreditCard,
	DollarSign,
	Users
} from 'react-feather';

export const MENUITEMS = [
	{
		title: 'Vezérlőpult', path: '/', icon: Home, type: 'link', badgeType: 'primary', active: false
	},
	{
		title: 'Bevételek', icon: CreditCard, type: 'sub', active: false, children: [
			{
				title: 'Számlák ', type: 'sub', children: [
					{title: 'Megtekintés', type: 'link', path: '/invoices'},
					{title: 'Új számla', type: 'link', path: '/invoices/create'},
				]
			},
			{
				title: 'Nyugták ', type: 'sub', children: [
					{title: 'Megtekintés', type: 'link', path: '/receipts'},
					{title: 'Új nyugta', type: 'link', path: '/receipts/create'},
				]
			},
		]
	},
	{
		title: 'Kiadások', icon: DollarSign, type: 'sub', active: false, children: [
			{title: 'Megtekintés', type: 'link', path: '/outlays'},
			{title: 'Új kiadás', type: 'link', path: '/outlays/create'},
		]
	},
	{
		title: 'Partnerek', icon: Users, type: 'sub', active: false, children: [
			{title: 'Megtekintés', type: 'link', path: '/partners'},
			{title: 'Új partner', type: 'link', path: '/partners/create'},
		]
	},
];
