import React, {Fragment, useState} from 'react';
import {connect, useSelector} from 'react-redux';

import Breadcrumb from '../../../components/common/breadcrumb';

import InvoiceForm from '../form';

import {createOutlay} from '../../../actions/outlay.action';

const InvoiceOutlayCreate = ({history, createOutlay}) => {
	const accessToken = useSelector(content => content.user.accessToken);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Fragment>
			<Breadcrumb title="Létrehozás" parent="Kiadások"/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<InvoiceForm
							isLoading={isLoading}
							isCreate={true}
							isIncome={false}
							submitFunction={async (data) => {
								setIsLoading(true);
								await createOutlay(accessToken, data)
									.then(() => {
										setIsLoading(false);
										history.push(`${process.env.PUBLIC_URL}/outlays`);
									})
									.catch(err => {
										setIsLoading(false);
									});
							}}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default connect(null, {createOutlay})(InvoiceOutlayCreate);
