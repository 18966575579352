import React, {useEffect, useState} from 'react';
import {connect, useSelector, useDispatch} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import Moment from 'moment';
import Currency from 'react-currency-formatter';

import Dropzone from 'react-dropzone';
import {Button} from 'reactstrap';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {HTTP_STATUS, useApi} from '../../hooks/useApi';
import {USER_LOG_OUT} from '../../constant/actionTypes';
import ErrorSpan from '../../components/form/errorSpan';
import {formatSize} from '../../components/common/formatter/FileSize';
import {addInvoiceDocument} from '../../actions/invoice.action';
import {addOutlayDocument} from '../../actions/outlay.action';

const InvoiceForm = ({invoice, submitFunction, isLoading, isCreate, isIncome, addInvoiceDocument, addOutlayDocument}) => {
	const {register, handleSubmit, errors, control} = useForm();
	const [get] = useApi();
	const dispatch = useDispatch();

	const accessToken = useSelector(content => content.user.accessToken);

	const [partners, setPartners] = useState([]);
	const [partnersIsLoading, setPartnersIsLoading] = useState(false);
	const [vats, setVats] = useState([]);
	const [vatsIsLoading, setVatsIsLoading] = useState(false);
	const [vatPercents, setVatPercents] = useState([]);

	const [subTotal, setSubTotal] = useState(0);
	const [total, setTotal] = useState(0);
	const [currency, setCurrency] = useState('HUF');
	const [uploadedDocuments, setUploadedDocuments] = useState([]);
	const [uploadedDocumentIds, setUploadedDocumentIds] = useState([]);
	const [invoiceItems, setInvoiceItems] = useState([]);
	const [invoiceItemCount, setInvoiceItemCount] = useState(0);

	useEffect(() => {
		setPartnersIsLoading(true);
		get(`/api/v1/partners/`, accessToken)
			.then(response => {
				setPartnersIsLoading(false);
				setPartners(response.data);
			})
			.catch(err => {
				setPartnersIsLoading(false);
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						if (isIncome) {
							toast.error('A vevők nem találhatók!');
						} else {
							toast.error('Az eladók nem találhatók!');
						}
						break;
					}
				}
			});

		setVatsIsLoading(true);
		get(`/api/v1/billing/vats/`, accessToken)
			.then(response => {
				setVatsIsLoading(false);
				setVats(response.data);

				let vatPercentList = [];
				for (let vat of response.data) {
					vatPercentList[vat.id] = vat.value;
				}
				setVatPercents(vatPercentList);
			})
			.catch(err => {
				setVatsIsLoading(false);
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('Az ÁFA kulcsok nem találhatók!');
						break;
					}
				}
			});
		// eslint-disable-next-line
	}, []);

	const onSubmit = data => {
		if (data !== '') {
			data = {
				...data,
				date: Moment(data.date).format('YYYY-MM-DD'),
				fulfillment_date: Moment(data.fulfillment_date).format('YYYY-MM-DD'),
				due_date: Moment(data.due_date).format('YYYY-MM-DD'),
				transactionType: isIncome ? 'INCOME' : 'OUTLAY',
				documents: uploadedDocumentIds
			};

			submitFunction(data);
		} else {
			errors.showMessages();
		}
	};

	const handleInvoiceItemValueChange = e => {
		const items = [...invoiceItems];
		items[e.target.dataset.index][e.target.dataset.field] = parseInt(e.target.value);
		setInvoiceItems(items);

		calculateSubTotal();
		calculateTotal();
	};

	const calculateSubTotal = () => {
		let subTotal = 0;

		for (let item of invoiceItems) {
			subTotal += (item.unit_price || 0) * (item.quantity || 0);
		}

		setSubTotal(subTotal);
	}

	const calculateTotal = () => {
		let total = 0;

		for (let item of invoiceItems) {
			total += (item.unit_price || 0) * (item.vat_id ? (1 + vatPercents[item.vat_id]) : 1) * (item.quantity || 0);
		}

		setTotal(total);
	}

	return (
		<form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
			<div className="card card-absolute">
				<div className="card-header bg-secondary">
					<h5>Számla adatai</h5>
				</div>
				<div className="card-body">
					<div className="form-row">
						<div className="col-md-4 mb-3">
							<label htmlFor="invoice_number">Sorszám</label>
							<input
								className="form-control"
								name="invoice_number"
								id="invoice_number"
								type="text"
								placeholder="Sorszám"
								ref={register({required: true})}
							/>
							<ErrorSpan>{errors.invoice_number && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-5 mb-3">
							<label htmlFor="partner">{isIncome ? 'Vevő' : 'Eladó'}</label>
							{partnersIsLoading ? <h6><i className="fa fa-spinner fa-spin"/>&nbsp;Betöltés</h6> :
								<select
									className="form-control"
									name="partner"
									id="partner"
									ref={register({required: true})}
								>
									<option value="">Válassz</option>
									{partners !== null && partners.map((partner) => {
										return <option value={partner.id} key={`partner-${partner.id}`}>{partner.name}</option>;
									})}
								</select>
							}
							<ErrorSpan>{errors.partner && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-4 mb-3">
							<label htmlFor="date">Számla kelte</label>
							<Controller
								as={DatePicker}
								control={control}
								valueName="selected"
								onChange={([selected]) => selected}
								name="date"
								id="date"
								className="form-control"
								placeholderText="Számla kelte"
								autoComplete="off"
								dateFormat="yyyy-MM-dd"
								dropdownMode="scroll"
								rules={{required: true}}
							/>
							<ErrorSpan>{errors.date && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-4 mb-3">
							<label htmlFor="date">Teljesítés kelte</label>
							<Controller
								as={DatePicker}
								control={control}
								valueName="selected"
								onChange={([selected]) => selected}
								name="fulfillment_date"
								id="fulfillment_date"
								className="form-control"
								placeholderText="Teljesítés kelte"
								autoComplete="off"
								dateFormat="yyyy-MM-dd"
								dropdownMode="scroll"
								rules={{required: true}}
							/>
							<ErrorSpan>{errors.date && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-4 mb-3">
							<label htmlFor="date">Fizetési határidő</label>
							<Controller
								as={DatePicker}
								control={control}
								valueName="selected"
								onChange={([selected]) => selected}
								name="due_date"
								id="due_date"
								className="form-control"
								placeholderText="Fizetési határidő"
								autoComplete="off"
								dateFormat="yyyy-MM-dd"
								dropdownMode="scroll"
								rules={{required: true}}
							/>
							<ErrorSpan>{errors.date && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-4 mb-3">
							<label htmlFor="payment_method">Fizetési mód</label>
							<select
								className="form-control"
								name="payment_method"
								id="payment_method"
								ref={register({required: true})}
							>
								<option value="">Válassz</option>
								<option value="BANK_TRANSFER">Átutalás</option>
								<option value="CASH">Készpénz</option>
								<option value="PAYPAL">PayPal</option>
								<option value="CREDIT_CARD">Bankkártya</option>
							</select>
							<ErrorSpan>{errors.payment_method && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-4 mb-3 input-disabled">
							<label>Nettó összeg</label>
							<div className="form-control">
								<Currency
									quantity={subTotal}
									currency={currency}
								/>
							</div>
						</div>
						<div className="col-md-4 mb-3 input-disabled">
							<label>Végösszeg</label>
							<div className="form-control">
								<Currency
									quantity={total}
									currency={currency}
								/>
							</div>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-4 mb-3">
							<label htmlFor="currency">Pénznem</label>
							<select
								className="form-control"
								name="currency"
								id="currency"
								ref={register({required: true})}
								onChange={e => setCurrency(e.target.value)}
							>
								<option value='HUF'>HUF</option>
								<option value='EUR'>EUR</option>
								<option value='USD'>USD</option>
								<option value='GBP'>GBP</option>
							</select>
							<ErrorSpan>{errors.currency && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
						<div className="col-md-4 mb-3">
							<label htmlFor="exchange_rate">Árfolyam</label>
							<input
								className="form-control"
								name="exchange_rate"
								id="exchange_rate"
								type="number"
								placeholder="Árfolyam"
								defaultValue="1"
								ref={register({required: true})}
							/>
							<ErrorSpan>{errors.exchange_rate && 'A mező kitöltése kötelező!'}</ErrorSpan>
						</div>
					</div>
				</div>
			</div>
			<div className="card card-absolute">
				<div className="card-header bg-secondary">
					<h5>Dokumentumok</h5>
				</div>
				<div className="card-body">
					<div>
						<ul>
							{uploadedDocuments.map((file, idx) => {
								return (
									<li key={`uploadedFile-${idx}`}>{file.name} ({file.size})</li>
								);
							})}
						</ul>
					</div>
					<div className="mb3">
						<Dropzone
							onDrop={files => {
								files.forEach((file) => {
									if (isIncome) {
										addInvoiceDocument(accessToken, {file})
											.then(id => {
												setUploadedDocumentIds(ids => {
													return [
														...ids,
														id
													];
												});
											});
									} else {
										addOutlayDocument(accessToken, {file})
											.then(id => {
												setUploadedDocumentIds(ids => {
													return [
														...ids,
														id
													];
												});
											});
									}

									setUploadedDocuments(documents => {
										return [
											...documents,
											{
												name: file.name,
												size: formatSize(file.size)
											}
										];
									});
								});
							}}
							accept='.jpg,.jpeg,.png,.pdf'
							disableClick
						>
							{({getRootProps, getInputProps}) => (
								<section>
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<Button
											color="warning"
											outline={true}
											onClick={(e) => {
												e.preventDefault();
											}}
										>
											Feltöltés
										</Button>
									</div>
								</section>
							)}
						</Dropzone>
					</div>
				</div>
			</div>
			<div className="card card-absolute">
				<div className="card-header bg-secondary">
					<h5>Tételek</h5>
				</div>
				<div className="card-body">
					<div className="form-row">
						<div className="col mb-3">
							<Button
								color="success"
								size="sm"
								onClick={(e) => {
									e.preventDefault();

									setInvoiceItems(items => {
										return [
											...items,
											{
												idx: invoiceItemCount,
												name: null,
												description: null,
												quantity: null,
												unit: null,
												unit_price: null,
												vat_id: null,
											}
										];
									});

									setInvoiceItemCount(count => count + 1);
								}}
							>
								<i className="fa fa-plus"/>
							</Button>
						</div>
					</div>
					{invoiceItems.map((item) => {
						return (
							<div className="form-row" key={`invoice-item-${item.idx}`}>
								<div className="col-md-3 mb-3">
									<input
										className="form-control"
										name={`items[${item.idx}][name]`}
										id={`items-${item.idx}-name`}
										type="text"
										placeholder="Megnevezés"
										ref={register({required: true})}
									/>
									<ErrorSpan>{errors.items && errors.items[item.idx] && errors.items[item.idx].name && 'A mező kitöltése kötelező!'}</ErrorSpan>
								</div>
								<div className="col-md-3 mb-3">
									<input
										className="form-control"
										name={`items[${item.idx}][description]`}
										id={`items-${item.idx}-description`}
										type="text"
										placeholder="Megjegyzés"
										ref={register({})}
									/>
								</div>
								<div className="col-md-1 mb-3">
									<input
										className="form-control"
										name={`items[${item.idx}][quantity]`}
										id={`items-${item.idx}-quantity`}
										data-index={item.idx}
										data-field='quantity'
										type="number"
										placeholder="Mennyiség"
										ref={register({required: true})}
										onChange={handleInvoiceItemValueChange}
									/>
									<ErrorSpan>{errors.items && errors.items[item.idx] && errors.items[item.idx].quantity && 'A mező kitöltése kötelező!'}</ErrorSpan>
								</div>
								<div className="col-md-1 mb-3">
									<input
										className="form-control"
										name={`items[${item.idx}][unit]`}
										id={`items-${item.idx}-unit`}
										type="text"
										placeholder="Egység"
										ref={register({required: true})}
									/>
									<ErrorSpan>{errors.items && errors.items[item.idx] && errors.items[item.idx].unit && 'A mező kitöltése kötelező!'}</ErrorSpan>
								</div>
								<div className="col-md-1 mb-3">
									<input
										className="form-control"
										name={`items[${item.idx}][unit_price]`}
										id={`items-${item.idx}-unit_price`}
										data-index={item.idx}
										data-field='unit_price'
										type="number"
										placeholder="Egységár"
										ref={register({required: true})}
										onChange={handleInvoiceItemValueChange}
									/>
									<ErrorSpan>{errors.items && errors.items[item.idx] && errors.items[item.idx].unit_price && 'A mező kitöltése kötelező!'}</ErrorSpan>
								</div>
								<div className="col-md-1 mb-3">
									{vatsIsLoading ? <h6><i className="fa fa-spinner fa-spin"/>&nbsp;Betöltés</h6> :
										<select
											className="form-control"
											name={`items[${item.idx}][vat_id]`}
											id={`items-${item.idx}-vat_id`}
											data-index={item.idx}
											data-field='vat_id'
											ref={register({required: true})}
											onChange={handleInvoiceItemValueChange}
										>
											<option value="">Válassz</option>
											{vats !== null && vats.map((vat) => {
												return <option value={vat.id} key={`items-${item.idx}-vat-${vat.id}`}>{vat.description}</option>;
											})}
										</select>
									}
									<ErrorSpan>{errors.items && errors.items[item.idx] && errors.items[item.idx].vat_id && 'A mező kitöltése kötelező!'}</ErrorSpan>
								</div>
								<div className="col-md-2 mb-3">
									<div className="form-row">
										<div className="col-9 input-disabled">
											<div className="form-control">
												<Currency
													quantity={(item.quantity || 0) * (item.unit_price || 0) * (item.vat_id ? (1 + vatPercents[item.vat_id]) : 1)}
													currency={currency}
												/>
											</div>
										</div>
										<div className="col-3 d-flex">
											<Button
												color="danger"
												size="sm"
												block={true}
												onClick={(e) => {
													e.preventDefault();

													setInvoiceItems(items => {
														items.splice(item.idx, 1);
														return [...items];
													});
												}}
											>
												<i className="fa fa-minus fa-fw"/>
											</Button>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="form-row">
				<div className="col-12 mb-3">
					<Button color="success" type="submit">
						{isLoading ? <><i className="fa fa-spinner fa-spin"/>&nbsp;</> : ''}{isCreate ? 'Létrehozás' : 'Módosítás'}
					</Button>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = ({user}) => {
	return {user};
};

export default connect(mapStateToProps, {addInvoiceDocument, addOutlayDocument})(InvoiceForm);
