import React from 'react';

export const formatSize = (bytes, decimals) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = (!isNaN(decimals) || decimals < 0) ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const FileSize = ({bytes, decimals = 2}) => {
	return (
		<span>{formatSize(bytes, decimals)}</span>
	);
}

export default FileSize;
