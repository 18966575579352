import {toast} from 'react-toastify';
import {HTTP_STATUS, useApi} from '../hooks/useApi';
import {
	USER_LOG_OUT,
	FETCH_PARTNERS,
	FETCH_PARTNERS_REJECTED,
	FETCH_PARTNERS_FULFILLED,
	CREATE_PARTNER,
	UPDATE_PARTNER,
	DELETE_PARTNER
} from '../constant/actionTypes';

const [get, post, remove] = useApi();

export const fetchPartners = (accessToken) => dispatch => {
	dispatch({type: FETCH_PARTNERS});
	get('/api/v1/partners/', accessToken)
		.then((response) => {
			dispatch({type: FETCH_PARTNERS_FULFILLED, payload: response.data});
		})
		.catch((err) => {
			switch (err.response.status) {
				case HTTP_STATUS.UNAUTHORIZED: {
					dispatch({type: USER_LOG_OUT});
					toast.error('Érvénytelen felhasználó!');
					break;
				}
				default: {
					dispatch({type: FETCH_PARTNERS_REJECTED, payload: err});
					toast.error('A partnerek betöltése sikertelen!');
					break;
				}
			}
		});
};

export const createPartner = (accessToken, data) => dispatch => {
	return new Promise((resolve, reject) => {
		post('/api/v1/partners/create', accessToken, data)
			.then((response) => {
				dispatch({type: CREATE_PARTNER, payload: response.data});
				toast.success('A partner sikeresen hozzáadva!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A partner hozzáadása sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};

export const updatePartner = (accessToken, data, id) => dispatch => {
	return new Promise((resolve, reject) => {
		post(`/api/v1/partners/update/${id}`, accessToken, data)
			.then((response) => {
				dispatch({type: UPDATE_PARTNER, payload: response.data});
				toast.success('A partner sikeresen módosítva!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A partner módosítása sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};

export const deletePartner = (id, accessToken) => dispatch => {
	return new Promise((resolve, reject) => {
		remove(`/api/v1/partners/delete/${id}`, accessToken)
			.then((response) => {
				dispatch({type: DELETE_PARTNER, payload: id});
				toast.success('A partner sikeresen törölve!');
				resolve();
			})
			.catch((err) => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						toast.error('A partner törlése sikeretelen!');
						break;
					}
				}
				reject();
			});
	});
};
