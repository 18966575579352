import React, {Fragment, useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {USER_LOG_OUT} from '../../constant/actionTypes';
import {HTTP_STATUS, useApi} from '../../hooks/useApi';

import Breadcrumb from '../../components/common/breadcrumb';

import PartnerForm from './form';

import {updatePartner} from '../../actions/partner.action';

const PartnerUpdate = ({history, updatePartner}) => {
	const dispatch = useDispatch();
	const {id} = useParams();
	const [get] = useApi();

	const accessToken = useSelector(content => content.user.accessToken);
	const [partner, setPartner] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		get(`/api/v1/partners/get/${id}`, accessToken)
			.then(response => {
				setPartner(response.data);
			})
			.catch(err => {
				switch (err.response.status) {
					case HTTP_STATUS.UNAUTHORIZED: {
						dispatch({type: USER_LOG_OUT});
						toast.error('Érvénytelen felhasználó!');
						break;
					}
					default: {
						history.push(`${process.env.PUBLIC_URL}/partners`);
						toast.error('A partner nem található!');
						break;
					}
				}
			});
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Módosítás" parent="Partnerek"/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<PartnerForm
							partner={partner}
							isLoading={isLoading}
							isCreate={false}
							submitFunction={async (data) => {
								setIsLoading(true);
								await updatePartner(accessToken, data, id)
									.then(() => {
										setIsLoading(false);
										history.push(`${process.env.PUBLIC_URL}/partners`);
									})
									.catch(err => {
										setIsLoading(false);
									});
							}}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default connect(null, {updatePartner})(PartnerUpdate);
