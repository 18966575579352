import React, {Fragment, useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {Button} from 'reactstrap';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Breadcrumb from '../../components/common/breadcrumb';
import {fetchPartners, deletePartner} from '../../actions/partner.action';

const PartnerList = ({partners, fetchPartners, deletePartner}) => {
	const accessToken = useSelector(content => content.user.accessToken);

	useEffect(() => {
		fetchPartners(accessToken);
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Megtekintés" parent="Partnerek"/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-header">
								<div className="pull-right">
									<Button type="a" color="success" size="sm" title="Új partner" href={`${process.env.PUBLIC_URL}/partners/create`}>
										<i className="fa fa-plus fa-fw"/>
									</Button>
								</div>
								<h5>Partnerek</h5>
							</div>
							<div className="card-body">
								<table className="table table-hover">
									<thead>
										<tr>
											<th>Név</th>
											<th>E-mail</th>
											<th>Telefonszám</th>
											<th>Adószám</th>
											<th className="text-center">Műveletek</th>
										</tr>
									</thead>
									<tbody>
										{partners.fetching && !partners.fetched ? <tr>
												<td colSpan={5} className="text-center">
													<h5><i className="fa fa-spinner fa-spin"/>&nbsp;Betöltés</h5>
												</td>
											</tr> :
											partners.data !== null ?
												partners.data.map((partner, idx) => <tr key={idx}>
														<td>{partner.name}</td>
														<td>{partner.email}</td>
														<td>{partner.phone ? partner.phone : <em>--- Nincs megadva ---</em>}</td>
														<td>{partner.vat_number ? partner.vat_number : <em>--- Nincs megadva --- </em>}</td>
														<td className="text-center">
															<Button color="info" size="sm" title="Szerkesztés" tag="a" href={`/partners/update/${partner.id}`}>
																<i className="fa fa-pencil fa-fw"/>
															</Button>
															&nbsp;
															<Button
																color="danger"
																size="sm"
																title="Törlés"
																onClick={() => {
																	confirmAlert({
																		title: 'Megerősítés',
																		message: `Biztosan törlöd a partnert? (${partner.name})`,
																		buttons: [
																			{
																				label: 'Igen',
																				onClick: () => {
																					deletePartner(partner.id, accessToken);
																				}
																			},
																			{
																				label: 'Nem'
																			}
																		]
																	});
																}}
															>
																<i className="fa fa-trash fa-fw"/>
															</Button>
														</td>
													</tr>
												) : <tr>
													<td colSpan={5} className="text-center">
														<h5>Nincsenek partnerek</h5>
													</td>
												</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = ({partners}) => {
	return {partners};
};

export default connect(mapStateToProps, {fetchPartners, deletePartner})(PartnerList);
