import {PURGE} from 'redux-persist';
import {apiResponse} from './initialState';

import {
	FETCH_RECEIPTS,
	FETCH_RECEIPTS_REJECTED,
	FETCH_RECEIPTS_FULFILLED,
	CREATE_RECEIPT,
	DELETE_RECEIPT
} from '../constant/actionTypes';

export default (state = apiResponse, action) => {
	switch (action.type) {
		case FETCH_RECEIPTS: {
			return {
				...state,
				fetching: true
			};
		}

		case FETCH_RECEIPTS_REJECTED: {
			return {
				...state,
				fetching: false,
				error: action.payload
			};
		}

		case FETCH_RECEIPTS_FULFILLED: {
			return {
				...state,
				error: null,
				fetching: false,
				fetched: true,
				data: action.payload
			};
		}

		case CREATE_RECEIPT: {
			return {
				...state,
				data: [
					...state.data,
					action.payload
				]
			};
		}

		case DELETE_RECEIPT: {
			return {
				...state,
				data: state.data.filter(receipt => receipt.id !== action.payload),
			}
		}

		case PURGE: {
			return {};
		}

		default: {
			return state;
		}
	}
}
